@import 'main.scss';
.sectionContainer {
  width: 100%;
  padding: 100px 0 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 100dvh;
  gap: 32px;

  @media screen and (min-width: 1000px) {
    gap: 50px;
  }

  @media screen and (min-width: 1200px) {
    justify-content: space-between;

    & > :first-child {
      margin: auto 0;
    }

    & > :last-child {
      align-self: flex-end;
    }
  }

  @media screen and (min-width: 1600px) {
    padding-bottom: 50px;
  }
}
