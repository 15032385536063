@import 'main.scss';
.containerOuter {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .containerInner {
    height: 100vh;
    width: auto;
    display: flex;
    flex-direction: row;
    position: relative;

    .timeline {
      position: absolute;
      bottom: 32px;
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      gap: 8px;

      .timelineBar {
        width: 1px;
        height: 8px;
        background-color: var(--primary);
      }

      .timelineNumberConatiner {
        display: flex;
        top: -5px;
        left: 6%;
        position: absolute;
        width: 94%;
        justify-content: space-between;

        .timelineNumber {
          font-size: 16px;
          font-family: var(--font-avant);
          line-height: 100%;
          background-color: var(--background);
          width: 38px;
          text-align: center;
        }
      }
    }

    .containerTitle {
      position: absolute;
      z-index: 5;
      left: 0;
      bottom: 45px;
      margin-bottom: 45px;
      color: var(--primary);
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 350px;

      font-size: 50px;
      line-height: 60px;

      @media screen and (min-width: 1001px) {
        max-width: 750px;
        font-size: 90px;
        line-height: 90px;
      }

      @media screen and (min-width: 1201px) {
        max-width: 830px;
        font-size: 100px;
        line-height: 100px;
      }

      p {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 1000px) {
          flex-direction: row;
        }
        :first-child {
          display: flex;

          &::after {
            content: "\2005";
          }
        }

        :last-child {
          width: fit-content;
          -webkit-text-stroke: 1.5px var(--primary);
          paint-order: stroke fill;
          color: transparent;

          @media screen and (min-width: 1001px) {
            -webkit-text-stroke: 2px var(--primary);
          }

          @media screen and (min-width: 1201px) {
            -webkit-text-stroke: 3px var(--primary);
          }
        }
      }
    }

    .containerImages {
      position: absolute;
      height: 100%;
      display: flex;
      width: auto;
      align-items: center;
      transform-style: preserve-3d;
      z-index: 5;
      gap: 100px;

      @media screen and (min-width: 1000px) {
        padding-left: 378px;
      }

      @media screen and (min-width: 1200px) {
        padding-left: 720px;
      }

      @media screen and (min-width: 1600px) {
        padding-left: 660px;
      }

      @media screen and (min-width: 1900px) {
        padding-left: 560px;
      }

      > div:nth-child(2) picture,
      div:nth-child(6) picture,
      div:nth-child(9) picture,
      div:nth-child(13) picture,
      div:nth-child(16) picture {
        position: relative;
        transform: translateY(-50%);
        width: 349px;
        height: 233px;

        @media screen and (min-width: 1200px) {
          transform: translateY(-35%);
          width: 464px;
          height: 310px;
        }

        @media screen and (min-height: 800px) {
          transform: translateY(-55%);
        }
      }

      > div:nth-child(4) picture,
      div:nth-child(8) picture,
      div:nth-child(11) picture,
      div:nth-child(15) picture,
      div:nth-child(18) picture {
        position: relative;
        transform: translateY(-20%);
        width: 475px;
        height: 316px;

        @media screen and (min-width: 1200px) {
          transform: translateY(-5.5%);
          width: 766px;
          height: 510px;
        }

        @media screen and (min-height: 800px) {
          transform: translateY(-15%);
        }
      }

      > div:nth-child(3) picture,
      div:nth-child(5) picture,
      div:nth-child(7) picture,
      div:nth-child(10) picture,
      div:nth-child(12) picture,
      div:nth-child(14) picture,
      div:nth-child(17) picture,
      div:nth-child(19) picture {
        position: relative;
        transform: translateY(35%);
        width: 256px;
        height: 170px;

        @media screen and (min-width: 1200px) {
          transform: translateY(50%);
          width: 341px;
          height: 226px;
        }

        @media screen and (min-height: 800px) {
          transform: translateY(75%);
        }
      }

      .imageDetail {
        position: absolute;
        bottom: 28px;
        font-size: 16px;
        font-family: var(--font-avant);
        line-height: 100%;
        background-color: var(--background);
        width: 38px;
        text-align: center;
      }

      picture {
        overflow: hidden;
        border-radius: 15px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        perspective: 1500px;
        overflow: hidden;

        div {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            45deg,
            rgba(175, 46, 255, 1) 0%,
            rgba(76, 40, 130, 1) 100%
          );
          z-index: 10;
          opacity: 0.2;
          pointer-events: none;
        }

        img {
          position: absolute;
          width: 126%;
          height: 100%;
          max-width: none !important;
          object-fit: cover;
          object-position: center center;

          @media screen and (hover: hover) {
            transition: width 300ms ease-in-out;

            &:hover {
              width: 135%;
            }
          }
        }
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .popupContent {
      width: 652px;
      height: 552px;
      padding: 32px 32px 48px 32px;
      background-color: var(--white);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      picture {
        width: 100%;
        height: 392px;
        border-radius: 15px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      p {
        font-family: var(--font-sora);
        font-size: 20px;
        line-height: 28px;
        color: var(--primary);
        font-weight: 300;
      }
    }
  }
}
