@import 'main.scss';
.keyTakeawaysContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 64px;
  margin-bottom: 24px;

  .keyTakeawaysWrap {
    font-family: var(--font-avant);
    background-color: var(--white);
    border-bottom: 1px solid #d9d9d9;
    border-radius: 15px;
    padding: 0;
    max-width: 680px;
    width: 100%;
    margin: 0 auto;

    .title {
      background-color: var(--secondary);
      border-radius: 15px 15px 0 0;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      gap: 5px;

      h2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0.65px;
        text-transform: uppercase;
        color: var(--white);
      }
    }
    ol {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px 24px;
    }
    .item {
      font-family: var(--font-sora);
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
    }
  }
}
