@import 'main.scss';
.sectionContainer {
  min-height: 400px;
  width: 100%;
  padding-top: 130px;
  margin-bottom: 70px;

  @media screen and (min-width: 1000px) {
    padding-top: 170px;
    height: calc(100vh - 170px);
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 330px;
  }

  @media screen and (min-width: 1600px) {
    margin-bottom: 287px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 0px;
    max-width: 1000px;
    justify-content: center;

    h1 {
      color: var(--primary);
      display: flex;
      flex-wrap: wrap;
      max-width: 352px;

      @media screen and (min-width: 1000px) {
        max-width: 642px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 730px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 880px;
      }

      .firstWord {
        display: inline-flex;

        &::after {
          content: "\2005";
        }
      }
    }

    p {
      margin-top: 24px;
      color: var(--primary);
      font-family: var(--font-sora);
      font-size: 16px;
      line-height: 22px;
      font-style: normal;
      font-weight: 300;
      max-width: 500px;

      @media screen and (min-width: 1000px) {
        font-size: 20px;
        line-height: 28px;
        margin-top: 40px;
        max-width: 642px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 650px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 700px;
      }
    }
  }
}
