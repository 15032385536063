@import 'main.scss';
.section {
  background-color: var(--primary);
  width: 100%;
  position: relative;
  padding: 25vh 0vh 15vh 0vh;

  @media screen and (min-width: 1000px) {
    padding: 25vh 0vh;
  }

  .overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    width: 110vw;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    pointer-events: all;

    &.heightMobile {
      height: 100vh;
    }

    @media screen and (min-width: 1000px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    width: 100%;
    position: relative;

    margin-top: 15vh;

    @media screen and (min-width: 1000px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    @media screen and (min-width: 1600px) {
      align-items: center;
    }

    .cardActivePos {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 48px);
      height: 614px;
      pointer-events: none;
      visibility: hidden;
      z-index: 120;

      @media screen and (min-width: 400px) {
        height: 647px;
      }

      @media screen and (min-width: 1000px) {
        position: absolute;
      }

      @media screen and (min-width: 1024px) {
        width: 550px;
        height: 570px;
      }

      @media screen and (min-width: 1200px) {
        width: 815px;
        height: 475px;
      }

      @media screen and (min-width: 1900px) {
        width: 1193px;
        height: 641px;
      }

      .horizontalCard {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        padding: 0px;
        pointer-events: none;
        visibility: visible;
        --before-height: 101%;
        --before-op: 1;
        background: var(--background);
        border-radius: 60px;
        display: none;
        overflow: hidden;
        @media screen and (min-width: 1200px) {
          padding: 52px;
        }

        @media screen and (min-width: 1900px) {
          padding: 90px;
        }

        &_cardContent {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          pointer-events: none;

          > *:first-child {
            position: absolute;
            top: 30px;
            right: 30px;
            display: flex;
            border: 1px solid var(--primary);
            border-radius: 500px;
            padding: 10px;
            height: 45px;
            width: 45px;

            @media screen and (min-width: 1000px) {
              display: none;
            }
          }

          .horizontalCard_textContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 74px 16px 0 16px;
            z-index: 10;

            @media screen and (min-width: 400px) {
              padding: 74px 30px 0 30px;
            }

            @media screen and (min-width: 1200px) {
              padding: 0px;
              width: 70%;
            }

            @media screen and (min-width: 1900px) {
              width: 625px;
            }

            .horizontalCard_textContentBottom {
              display: none;
              align-items: center;
              gap: 12px;

              @media screen and (min-width: 1200px) {
                display: flex;
                align-items: flex-start;
              }

              div {
                display: flex;
                flex-direction: column;
                gap: 0px;
                font-family: var(--font-avant);

                h3 {
                  font-size: 28px;
                  font-weight: 700;
                  color: var(--primary);
                }

                p {
                  font-family: var(--font-sora);
                  font-size: 14px;
                  font-weight: 100;
                }
              }

              @media screen and (min-width: 1200px) {
                gap: 24px;

                div {
                  gap: 6px;

                  h3 {
                    font-size: 45px;
                  }

                  p {
                    font-size: 14px;
                  }
                }
              }

              @media screen and (min-width: 1900px) {
                gap: 24px;

                div {
                  gap: 4px;

                  h3 {
                    display: flex;
                    font-size: 45px;
                    line-height: 50px;

                    span {
                      display: block;
                      text-align: center;
                      width: 45px;
                    }
                  }

                  p {
                    font-size: 20px;
                    line-height: 28px;
                  }
                }

                div:first-of-type {
                  width: 224px;
                }

                div:last-of-type {
                  width: 228px;
                }
              }
            }

            .horizontalCard_textContentTop {
              display: flex;
              flex-direction: column;
              gap: 4px;

              h2 {
                color: var(--primary);
                font-family: var(--font-avant);
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 45px;
                text-transform: uppercase;

                @media screen and (min-width: 1024px) {
                  font-size: 45px;
                  line-height: 55px;
                }

                @media screen and (min-width: 1200px) {
                  font-size: 52px;
                  line-height: 52px;
                }

                @media screen and (min-width: 1900px) {
                  font-size: 70px;
                  line-height: 70px;
                }
              }

              @media screen and (min-width: 1200px) {
                gap: 16px;
              }

              p {
                color: var(--primary);
                font-family: var(--font-sora);
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px;
                margin: 12px 0;

                @media screen and (min-width: 1200px) {
                  margin: 0;
                }

                @media screen and (min-width: 1900px) {
                  font-size: 20px;
                  line-height: 28px;
                }
              }

              .horizontalCard_role {
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: var(--primary);
                color: transparent;
                font-family: var(--font-avant);
                font-size: 20px;
                line-height: 28px;
                font-weight: 800;
                margin: 0;
                text-transform: uppercase;

                @media screen and (min-width: 400px) {
                  font-size: 28px;
                  line-height: 33px;
                  letter-spacing: 1.4px;
                }

                @media screen and (min-width: 1024px) {
                  font-size: 30px;
                  line-height: 35px;
                  letter-spacing: 1.6px;
                }

                @media screen and (min-width: 1200px) {
                  font-size: 35px;
                  line-height: 35px;
                  letter-spacing: 1.85px;
                }
              }

              .horizontalCard_socialContainer {
                position: relative;
                width: 40px;
                height: 40px;
                pointer-events: all;

                @media screen and (min-width: 400px) {
                  width: 50px;
                  height: 50px;
                }

                a {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 64px;
                  height: 64px;
                  overflow: hidden;
                  border-radius: 50%;
                  transition: background-color 0.5s;

                  @media screen and (hover: hover) {
                    &:hover {
                      background-color: #af2eff;

                      svg path {
                        fill: var(--white);
                      }
                    }
                  }

                  svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 50px;
                    height: auto;

                    @media screen and (min-width: 400px) {
                      width: 63px;
                      height: auto;
                    }
                  }
                }
              }
            }
          }

          .horizontalCard_imageContainer {
            width: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            bottom: 0px;
            height: 300px;

            @media screen and (min-width: 475px) {
              height: 400px;
            }

            @media screen and (min-width: 1200px) {
              height: 100%;
              justify-content: flex-end;
              width: calc(100% - 24px);
              right: -100px;
            }

            @media screen and (min-width: 1900px) {
              justify-content: flex-end;
              width: calc(100% - 46px);
            }

            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 10;
      pointer-events: none;
      gap: 30px;
      padding: 0;
      margin-bottom: 50px;
      width: 100%;

      @media screen and (min-width: 1000px) {
        gap: 50px;
        margin-bottom: 0;
        padding: 0;
        align-items: flex-start;
        position: sticky;
        top: 100px;
      }

      @media screen and (min-width: 1600px) {
        position: initial;
      }

      h2 {
        display: flex;
        flex-direction: column;

        :first-child {
          color: transparent;
          background-image: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0.5) 50%,
            var(--white) 50%
          );
        }

        :last-child {
          -webkit-text-stroke: 3px var(--white);
          paint-order: stroke fill;
          color: var(--primary);

          @media screen and (min-width: 1000px) {
            -webkit-text-stroke: 5px var(--white);
          }

          @media screen and (min-width: 1200px) {
            -webkit-text-stroke: 6px var(--white);
          }
        }
      }

      .description {
        opacity: 1;
        color: var(--white);
        font-family: var(--font-sora);
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        text-align: start;

        @media screen and (min-width: 1000px) {
          font-size: 20px;
          line-height: 28px;
          text-align: start;
          max-width: 500px;
        }
      }
    }

    .cardsContainer {
      width: 100%;
      height: 100%;
      pointer-events: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 30px 20px;

      @media screen and (min-width: 1000px) {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 240px);
        grid-template-rows: repeat(3, 380px);
        justify-content: center;
        gap: 12px;
      }

      @media screen and (min-width: 1350px) {
        grid-template-columns: repeat(3, 240px);
        grid-template-rows: repeat(2, 380px);
        align-self: center;
        width: max-content;
      }

      .mobileBTNs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        width: 100%;
        padding: 0 24px;
        z-index: 20;

        @media screen and (min-width: 1024px) {
          display: none;
        }

        & > div {
          height: 45px;
          width: 45px;
          border-radius: 100%;
          border: 1px solid var(--white);
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 10px;
        }

        .leftBTN {
          background-image: url("/about_team_lider/left-arrow.svg");
        }

        .rightBTN {
          background-image: url("/about_team_lider/right-arrow.svg");
        }
      }

      .cardPlaceholder {
        width: 240px;
        height: 380px;
        display: block;

        @media screen and (min-width: 1024px) {
          width: 100%;
          height: 100%;
          max-width: 240px;
          max-height: 380px;
        }

        .card {
          margin: 0 auto;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          pointer-events: none;
          position: relative;
          pointer-events: all;
          transform-style: preserve-3d;
          z-index: 0;
          --card-bradius: 38px;
          --before-height: 100%;
          --before-op: 0;

          .front {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--background);
            backface-visibility: hidden;
            border-radius: var(--card-bradius);
            overflow: hidden;
            z-index: 2;
            transform-style: preserve-3d;

            .cardContent {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              height: 100%;
              text-align: center;
              pointer-events: none;
              position: relative;

              .textContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 24px;
                max-height: 30%;
                gap: 4px;
                pointer-events: none;
                z-index: 1;

                h2 {
                  color: var(--primary);
                  font-family: var(--font-avant);
                  font-weight: 800;
                  font-size: 50px;
                  line-height: 50px;
                  text-transform: uppercase;
                  letter-spacing: 0.04em;
                }

                p {
                  text-transform: uppercase;
                  color: var(--primary);
                  font-family: var(--font-avant);
                  font-size: 20px;
                  font-weight: 800;
                  line-height: 20px;
                }
              }

              .imageContainer {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: none;
                position: absolute;
                inset: 0;

                img {
                  object-fit: cover;
                  height: 100%;
                  object-position: bottom !important;
                }
              }
            }
          }

          .back {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--background);
            backface-visibility: hidden;
            transform: rotateY(180deg) translateZ(1px);
            border-radius: var(--card-bradius);
          }

          .glow {
            position: absolute;
            top: 0;
            width: 100%;
            height: var(--before-height);
            border-radius: var(--card-bradius);
            z-index: 0;
            background-color: var(--secondary);
            transform: rotateY(180deg);
            opacity: var(--before-op);
          }
        }
      }
    }
  }
}
