@import 'main.scss';
.lastFooterContainer {
  display: flex;
  gap: 18px;
  align-items: flex-end;
  width: 100%;
  position: relative;
  font-family: var(--font-sora);
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;

  @media screen and (min-width: 400px) {
    align-items: center;
  }

  @media screen and (min-width: 1000px) {
    max-width: none;
    flex-direction: row-reverse;
  }

  .termsPrivacy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    flex-shrink: 0;
    order: 1;
    flex-direction: column;

    @media screen and (min-width: 1000px) {
      gap: 24px;
      order: 2;
      flex-direction: row;
      justify-content: flex-start;
    }

    @media screen and (min-width: 1200px) {
      width: auto;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: var(--primary);
      opacity: 0.7;

      @media screen and (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }

      @media screen and (min-width: 1000px) {
        text-align: center;
        font-size: 16px;
        line-height: 26px;
        width: max-content;
      }
    }
  }

  .copyRightItems {
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: max-content;
    justify-content: flex-start;
    flex-wrap: wrap;
    order: 2;
    width: 140px;

    @media screen and (min-width: 400px) {
      justify-content: center;
      width: auto;
    }

    :nth-child(3) {
      padding-left: 4px;
    }

    @media screen and (min-width: 1000px) {
      order: 4;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;

      @media screen and (min-width: 1000px) {
        font-size: 16px;
        line-height: 26px;
      }

      &.spanAfter {
        position: relative;
        padding-right: 5px;

        &::after {
          position: absolute;
          content: "";
          width: 5px;
          border-radius: 50%;
          height: 5px;
          background-color: var(--primary);
          top: 10px;
          right: -5px;
        }
      }
    }
  }

  .arrowUpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 3;
    position: relative;
    width: 36px;
    height: 36px;
    margin-left: 20px;

    @media screen and (min-width: 1000px) {
      margin-left: 0px;
    }

    @media screen and (min-width: 1200px) {
      order: 1;
    }

    @media screen and (min-width: 1600px) {
      width: 100%;
      max-width: 200px;
    }

    .svgContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 500px;
      background: var(--background);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      order: 3;
      border: 2px solid var(--primary);
      width: 40px;
      height: 40px;
      transition:
        width 0.5s,
        height 0.5s,
        background 0.5s,
        border 0.5s;

      @media screen and (min-width: 1000px) {
        width: 55px;
        height: 55px;
      }

      @media screen and (hover: hover) {
        &:hover {
          background: var(--secondary);
          border: 2px solid var(--secondary);
          width: 100px;
          height: 100px;

          svg {
            path {
              fill: var(--background);
            }
          }
        }
      }

      @media screen and (min-width: 1000px) {
        padding: 12px;
      }

      @media screen and (min-width: 1600px) {
        left: auto;
        top: auto;
        transform: none;
        right: 0;
      }

      svg {
        width: 20px;
        height: 20px;

        @media screen and (min-width: 1000px) {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
