@import 'main.scss';
.sectionContainer {
  width: 100%;
  margin: 150px 0;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;

  @media screen and (min-width: 1000px) {
    min-height: 100vh;
    margin: 200px 0;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    @media screen and (min-width: 1000px) {
      padding: 0;
      margin-bottom: 40px;
    }

    @media screen and (min-width: 1200px) {
      padding-top: 64px;
    }

    h2 {
      display: flex;
      flex-direction: column;
      overflow-wrap: break-word;
      color: var(--primary);

      :first-child {
        padding-left: 5px;
      }
    }

    p {
      margin-top: 24px;
      font-family: var(--font-sora);
      color: var(--primary);
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      max-width: 500px;

      @media screen and (min-width: 1000px) {
        max-width: 790px;
        font-size: 20px;
        line-height: 26px;
        margin-top: 40px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 1090px;
      }
    }
  }

  .buttonContainer {
    position: absolute;
    right: 10vw;
    bottom: -200px;
    z-index: 10;
    display: flex;

    @media screen and (min-width: 1000px) {
      right: 5vw;
    }

    @media screen and (min-width: 1200px) {
      right: 15vw;
    }
  }
}
