@import 'main.scss';
.testimonyContainer {
  margin-bottom: 48px;

  @media screen and (min-width: 1024px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  .testimonyWrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 680px;
    margin: 0 auto;

    svg {
      width: 100%;
      height: 100%;
      max-width: 55px;
      max-height: 55px;
      padding-bottom: 6px;
      padding-left: 8px;
    }

    .comment {
      font-family: var(--font-avant);
      font-size: 30px;
      font-weight: 400;
      line-height: 35px;
      color: var(--Primary, #17082f);
    }

    .author {
      display: flex;
      column-gap: 16px;
      color: var(--Primary, #17082f);

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      picture {
        width: 50px;
        height: 50px;

        .avatar {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .name {
        font-family: var(--font-sora);
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
      }

      .rol {
        font-family: var(--font-sora);
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
      }
    }
  }
}
