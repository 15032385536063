@import 'main.scss';
.heroContainer {
  padding: 150px 0 60px;
  font-family: var(--font-sora);
  color: var(--primary, #17082f);

  .data {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 32px;

    @media screen and (min-width: 1000px) {
      flex-direction: row;
    }

    picture {
      width: 110px;
      height: 110px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h1 {
        font-family: var(--font-avant);
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        text-transform: uppercase;
      }

      span {
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        padding-bottom: 10px;
      }

      .social {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: background-color 0.5s;
        // margin-left: -15px;

        svg,
        a {
          width: 100%;
          height: 100%;
        }

        @media screen and (hover: hover) {
          &:hover {
            background-color: #af2eff;

            svg {
              path {
                fill: var(--white);
              }
            }
          }
        }
      }
    }
  }

  p {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    color: var(--primary, #17082f);

    @media screen and (min-width: 1000px) {
      max-width: 950px;
    }
  }
}
