@import 'main.scss';
.wrapper {
  padding-top: 100px !important;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 30px !important;

  @media screen and (min-width: 1000px) {
    padding-top: 150px !important;
    gap: 32px;
    margin-bottom: 50px !important;
  }

  @media screen and (min-width: 1200px) {
    gap: 48px;
    margin-bottom: 70px !important;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (min-width: 1000px) {
      gap: 16px;
    }

    .title {
      font-family: var(--font-avant);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      -webkit-text-stroke-width: 1px;

      @media screen and (min-width: 1000px) {
        font-size: 45px;
        line-height: 55px;
        -webkit-text-stroke-width: 2px;
      }

      @media screen and (min-width: 1200px) {
        font-size: 55px;
        line-height: 65px;
        -webkit-text-stroke-width: 3px;
      }
    }
    .subtitle {
      font-family: var(--font-sora);
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;

      @media screen and (min-width: 1000px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .searchBar {
    border: 1px solid var(--primary);
    height: 100%;
    max-height: 44px;
    width: 100%;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    padding: 10px 16px;
    background-color: var(--white);
    max-width: 1000px;

    @media screen and (min-width: 1000px) {
      margin: 0 auto;
    }

    .icon {
      height: 24px;
      width: 24px;
    }

    input {
      display: flex;
      width: 100%;
      background-color: transparent;
      font-family: var(--font-sora);
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: var(--primary);

      &::placeholder {
        color: var(--primary);
      }
    }
  }
}

.sectionFoundContainer {
  padding-top: 70px;

  @media screen and (min-width: 1000px) {
    padding-top: 100px;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 150px;
  }
}
