@import 'main.scss';
.infoCards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1600px) {
    gap: 64px;
  }

  .firstRow {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1201px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .title {
      font-family: var(--font-avant);
      max-width: 773px;
      margin: auto;
      padding-bottom: 24px;
      position: relative;
      height: max-content;

      .title > * {
        pointer-events: none;
      }

      @media screen and (min-width: 1000px) {
        margin: 0;
        padding-bottom: 32px;
      }

      h1 {
        @media screen and (min-width: 1000px) and (hover: hover) {
          &:hover {
            -webkit-text-stroke-width: 3px;
            background-size: 100% 100%;
          }
        }
      }

      .body {
        display: none;

        p {
          @media screen and (min-width: 1000px) {
            font-family: var(--font-sora);
            font-size: 16px;
            font-weight: 300;
            line-height: 21px;
            text-align: left;
            color: var(--primary);
          }

          @media screen and (min-width: 1600px) {
            font-size: 20px;
            line-height: 28px;
          }
        }

        @media screen and (min-width: 1000px) {
          display: block;
        }
      }

      h1 {
        background:
          linear-gradient(var(--primary), var(--primary)) left no-repeat,
          var(--background);
        background-size: 0% 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: 0.3s ease-in-out;
        letter-spacing: 2.5px;
        font-size: 44px;
        line-height: 55px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: var(--primary);
        color: transparent;

        @media screen and (min-width: 1000px) {
          font-size: 70px;
          line-height: 75px;
          text-align: start;
          letter-spacing: 5px;
        }

        @media screen and (min-width: 1200px) {
          font-size: 80px;
          line-height: 80px;
        }

        @media screen and (min-width: 1600px) {
          font-size: 120px;
          line-height: 120px;
        }
      }

      .lineMask {
        position: absolute;
        overflow: hidden;

        @media screen and (min-width: 1000px) {
          right: -150px;
          max-width: 200px;
          top: 15px;
        }

        @media screen and (min-width: 1200px) {
          top: 100px;
          left: 320px;
          max-width: 200px;
        }

        @media screen and (min-width: 1600px) {
          max-width: 270px;
          top: 162px;
          left: 470px;
        }
      }

      .lineMaskEn {
        position: absolute;
        overflow: hidden;

        @media screen and (min-width: 1000px) {
          right: -200px;
          max-width: 200px;
          top: 15px;
        }

        @media screen and (min-width: 1200px) {
          top: 100px;
          left: 410px;
          max-width: 200px;
        }

        @media screen and (min-width: 1600px) {
          max-width: 250px;
          top: 150px;
          left: 600px;
        }
      }
    }

    .linksContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      width: 100%;

      @media screen and (min-width: 1000px) {
        flex-direction: row;
        align-items: flex-start;
        gap: 64px;
      }

      @media screen and (min-width: 1200px) {
        justify-content: center;
        width: max-content;
      }

      .linksNavigation {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 250px;
        width: 100%;

        @media screen and (min-width: 1000px) {
          align-items: flex-start;
        }

        @media screen and (min-width: 1200px) {
          width: 250px;
        }

        h2 {
          font-family: var(--font-sora);
          font-size: 22px;
          font-weight: 700;
          line-height: 27px;
          text-align: center;
        }

        .listLinks {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          @media screen and (min-width: 1000px) {
            gap: 12px;
            align-items: flex-start;
          }

          li {
            text-transform: capitalize;
            font-family: var(--font-sora);
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            transition: color 0.5s;
            color: var(--primary);
            opacity: 0.7;

            @media screen and (min-width: 1200px) {
              font-size: 20px;
              line-height: 25px;
            }

            @media screen and (hover: hover) {
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .secondRow {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    @media screen and (min-width: 1000px) {
      gap: 30px;
    }

    @media screen and (min-width: 1201px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 0px;
    }

    .infoSocial {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: 1000px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      @media screen and (min-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
      }

      .moreInfo {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .contactInfo {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;

          @media screen and (min-width: 1000px) {
            flex-direction: row;
            gap: 30px;
          }
        }

        span {
          font-family: var(--font-sora);
          font-size: 20px;
          font-weight: 400;
          line-height: 25px;

          @media screen and (min-width: 1200px) {
            font-size: 22px;
            line-height: 27px;
          }

          &:first-child {
            font-weight: 700;
          }

          &:not(:first-child) {
            opacity: 0.7;

            @media screen and (hover: hover) {
              &:hover {
                opacity: 1;
              }
            }
          }
        }

        .location {
          text-align: center;
          opacity: 0.7;
          font-family: var(--font-sora);
          font-size: 20px;
          font-weight: 400;
          line-height: 25px;

          @media screen and (min-width: 1200px) {
            font-size: 22px;
            line-height: 27px;
          }

          @media screen and (min-width: 1000px) {
            text-align: left;
          }
        }
      }

      .socialContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 32px;
        align-items: center;
        margin-top: 20px;

        @media screen and (min-width: 1000px) {
          margin-top: 0;
        }

        @media screen and (min-width: 1200px) {
          justify-content: flex-start;
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          transition: background-color 0.5s;

          @media screen and (min-width: 1000px) {
            width: 65px;
            height: 65px;
          }

          @media screen and (hover: hover) {
            &:hover {
              background-color: #af2eff;

              svg {
                path {
                  fill: var(--white);
                }
              }
            }
          }

          a {
            padding: 0px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          svg {
            width: 50px;
            height: 50px;

            path {
              fill: var(--primary);
            }
          }
        }
      }
    }
  }
}
