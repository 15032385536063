@import 'main.scss';
.starRating {
  display: flex;
  gap: 3px;

  svg {
    width: 17px;
    height: 17px;
  }
}
