@import 'main.scss';
.cardsCotaniner {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 1000px) {
    margin: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 24px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 515px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 100%;
    justify-content: flex-end;
  }

  .cardDetail {
    display: flex;
    padding: 12px 20px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #8c8c8c;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    user-select: none;
    max-width: 328px;
    justify-content: space-between;

    @media screen and (min-width: 1000px) {
      max-width: 252px;
      gap: 12px;
    }

    @media screen and (min-width: 1200px) {
      flex-wrap: nowrap;
    }

    @media screen and (min-width: 1600px) {
      max-width: 270px;
    }

    @media screen and (hover: hover) {
      &:hover {
        background: var(--white);
      }
    }

    .textSvg {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: max-content;

      span {
        font-family: var(--font-sora);
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }
    }
  }
}
