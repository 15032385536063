@import 'main.scss';
.sectionContainer {
  padding: 100px 0 160px 0px;
  min-height: 500px;
  width: 100%;
  margin-top: 100px;

  @media screen and (min-width: 1000px) {
    padding: 170px 0;
    height: 100dvh;
  }

  .containerCenter {
    height: 100%;
    display: flex;
    justify-content: center;

    .textContainer {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 32px;
      padding: 0px 24px;
      height: 100%;

      @media screen and (min-width: 1200px) {
        max-width: 950px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 1200px;
      }

      :first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      h2 {
        color: var(--primary);
        display: flex;
        flex-direction: column;
        align-items: center;

        :first-child {
          padding: 0px 5px;
        }
      }

      p {
        text-align: center;
        font-family: var(--font-sora);
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
        color: var(--primary);
        max-width: 700px;

        @media screen and (min-width: 1000px) {
          font-size: 20px;
          line-height: 28px;
          max-width: 530px;
        }

        @media screen and (min-width: 1200px) {
          max-width: 890px;
        }
      }
    }
  }
}
