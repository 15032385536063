@import 'main.scss';
.hero_wrapper {
  width: 100%;
  height: auto;
  padding: 90px 0 130px 0;

  .duckScene {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 1000px) {
    padding: 140px 0px;
    height: 100vh;
  }

  @media screen and (min-width: 1200px) {
    padding: 170px 0px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: 1000px) {
      gap: 50px;
    }

    h1 {
      color: var(--primary);
      overflow-wrap: break-word;
      display: flex;
      flex-direction: column;
    }

    p {
      z-index: 25;
      color: var(--primary);
      font-family: var(--font-sora);
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      max-width: 500px;

      @media screen and (min-width: 1000px) {
        max-width: 575px;
        font-size: 20px;
        line-height: 28px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 700px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 850px;
      }
    }
  }
}

.duck_trigger {
  position: absolute;
  width: 100%;
  height: 100vh;
  bottom: 0;
  z-index: 10;
  pointer-events: none;
}
