@import 'main.scss';
.sectionContainer {
  min-height: 100dvh;
  padding-top: 160px;
  width: 100%;
  font-family: var(--font-sora);

  .container {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 0px 24px;
    padding-bottom: 160px;

    h1 {
      color: var(--Primary, #17082f);
      font-family: var(--font-avant);
      font-size: 30px;
      line-height: 35px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;

      margin-top: 24px;

      @media screen and (min-width: 1000px) {
        font-size: 70px;
        line-height: 70px;
        margin-bottom: 64px;
      }
    }

    .bodyContainer {
      a {
        color: var(--secondary);
        text-decoration: underline;
        text-underline-offset: 2px;
      }

      strong {
        font-weight: 600;
      }

      p {
        font-family: var(--font-sora);
        font-weight: 300;
        margin-top: 30px;
        font-size: 20px;
        line-height: 30px;
      }

      h2 {
        font-family: var(--font-avant);
        margin-top: 40px;
        font-size: 32px;
        line-height: 40px;
        font-weight: 300;
      }

      h3 {
        font-family: var(--font-avant);
        margin-top: 40px;
        font-size: 26px;
        line-height: 40px;
        font-weight: 300;
      }

      h2 + p {
        margin-top: 0;
      }
    }
  }
}
