@import 'main.scss';
.chipButton {
  font-family: var(--font-avant);
  color: var(--White, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  padding: 10px 22px;
  text-transform: uppercase;
  z-index: 30;
  position: relative;
  border-radius: 500px;
  width: fit-content;
  overflow: hidden;
  box-sizing: border-box;
  letter-spacing: 0.7px;
  cursor: none;
  transition: color 0.5s ease-in-out;
  pointer-events: all;

  &.outline {
    color: var(--primary, #17082f);
    border: 1px solid var(--primary, #17082f);

    @media screen and (hover: hover) {
      &:hover {
        color: var(--white);
      }
    }
  }
  &.outline-white {
    color: var(--white);
    border: 1px solid var(--white);

    @media screen and (hover: hover) {
      &:hover {
        color: var(--primary);
      }
    }
  }

  &.category {
    color: var(--primary);
    padding: 30px 48px;
    border-radius: 10px;
    border: 1px solid var(--primary, #17082f);
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    text-transform: unset;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: wrap;
    max-width: unset;

    @media screen and (hover: hover) {
      &:hover {
        color: var(--white);
      }
    }
  }

  &.accent {
    display: none;
    color: var(--primary);
    background-color: var(--accent, #ffb800);

    @media screen and (min-width: 1000px) {
      display: flex;
    }
  }

  &.active {
    background-color: var(--secondary, #af2eff);
    color: #fff;

    @media screen and (min-width: 1000px) {
      display: none;
    }

    @media screen and (hover: hover) {
      &:hover {
        color: black;
      }
    }

    &.hidden {
      display: none;

      @media screen and (min-width: 1000px) {
        display: flex;
      }
    }
  }

  &.base {
    background-color: var(--white, #fff);
    color: var(--primary, #17082f);

    @media screen and (hover: hover) {
      &:hover {
        color: var(--primary, #17082f);
      }
    }
  }
}

.magnetic {
  display: inline-block;
  max-width: 300px;
  max-height: 300px;
  margin: -40px;
  padding: 40px;
  border-radius: 50%;
  z-index: 30;
  pointer-events: all;
  // border: 1px solid #000; // DEBUG PROPERTY

  &.magneticCategory {
    margin: unset;
    padding: unset;
    z-index: 20;
    max-width: unset;
  }
}

.magneticCursor {
  width: 100%;
  height: 200%;
  border-radius: 500px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transform: translate(-50%, -50%) scale(0);
  pointer-events: all;

  &.outline {
    background-color: var(--primary);
  }

  &.outline-white {
    background-color: var(--white);
  }

  &.accent {
    background-color: #ffd600;
  }

  &.active {
    background-color: var(--white);
  }

  &.category {
    background-color: var(--primary);
  }

  &.base {
    background-color: #ffd600;
  }
}
